.App {
  text-align: center;
}

html {
  scroll-behavior: smooth;
}

/* Fade */

.fade-in-section.is-visible {
  opacity: 1;
  transform: none;
  visibility: visible;
}

.fade-in-section {
  opacity: 0.5;
  transform: translateY(10vh);
  visibility: hidden;
  transition: opacity 0.6s ease-out, transform 1s ease-out;
  will-change: opacity, visibility;
}
